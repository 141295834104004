<template>
    <div id="wizard_container">
        <div id="top-wizard">
            <div id="progressbar"></div>
        </div>
        <!-- /top-wizard -->
        <div id="wrapped">
            <div id="middle-wizard">
                <div class="step">
                    <h3 class="main_question">
                        <strong>{{actualQuestionCounter}}/{{totalQuestionCounter}}</strong>
                        <span v-html="question.text"></span>
                    </h3>
                    <div class="mb-3" v-html="question.notes"></div>
                    <QuestionHandler></QuestionHandler>
                </div>
            </div>
            <!-- /middle-wizard -->
            <div id="bottom-wizard">
                <button v-if="actualQuestionCounter !== 1" type="button" name="backward" class="backward" v-on:click="previousQuestion">Precedente</button>
                <button v-if="!lastQuestion && currentQuestionAnswer" type="button" name="forward" class="forward" v-on:click="nextQuestion">Prosegui <span v-if="error" class="error error-forward" v-html="error"></span></button>
                <button v-if="lastQuestion" type="submit" name="process" class="submit" v-on:click="nextModule">Salva modulo e prosegui</button>
            </div>
            <!-- /bottom-wizard -->
        </div>
    </div>
    <!-- /Wizard container -->
</template>

<script>
  import QuestionHandler from "@/components/form/QuestionHandler";
  export default {
    name: "Wizard",
    components: {QuestionHandler},
    methods: {
      nextQuestion: function() {
        this.$store.dispatch('setCurrentQuestion', 1);
      },
      previousQuestion: function() {
        this.$store.dispatch('setCurrentQuestion', 0)
      },
      nextModule: function() {
        this.$store.dispatch('setNextModule')
      }
    },
    computed: {
      question: function() { return this.$store.getters.getCurrentQuestion },
      actualQuestionCounter: function() { return this.$store.getters.getCurrentQuestionPosition },
      totalQuestionCounter: function() { return this.$store.getters.getCurrentQuestionLength },
      lastQuestion: function() { return this.$store.getters.getCurrentQuestionIsLast },
      currentQuestionAnswer: function() { return this.$store.getters.getCurrentQuestionAnswer },
    }
  }
</script>

<style scoped>

</style>
