<template>
    <div class="form-group">
        <input
            class="form-control required"
            v-bind:placeholder="question.text"
            v-model="value"
            v-on:change="setValue"
        >
    </div>
</template>

<script>
  export default {
    name: "InputField",
    data: function () {
      return {
        value: ''
      }
    },
    mounted() {
      this.retrieveAnswer();
      this.handleChange();
    },
    methods: {
      handleChange: function() {
        this.$store.subscribe((mutation) => {
          if(mutation.type !== 'setCurrentQuestion')
            return;
          this.value = this.$store.getters.getCurrentQuestionAnswer.value;
        })
      },
      retrieveAnswer() {
        this.value = this.$store.getters.getCurrentQuestionAnswer.value;
      },
      setValue: function() {
        this.$store.dispatch('setQuestionAnswer', {
          value: this.value,
          questionId: this.question.id,
          questionType: this.question.type.slug
        } );
      }
    },
    computed: {
      question: function() {
        return this.$store.getters.getCurrentQuestion;
      }
    },
  }
</script>
