<template>
    <div class="radio-container">
        <div class="form-group" v-for="(q,idx) in answerOrder" v-bind:key="idx">
            <label class="container_radio version_2">{{q.text}}
                <input
                        type="radio"
                        name="radio_input"
                        v-bind:value="q.id"
                        class="required"
                        v-on:change="setValue(q.id,q.text)"
                        v-bind:checked="isChecked(q.id)"
                >
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</template>

<script>
  export default {
    name: "RadioField",
    methods: {
      isChecked( value ) {
        return this.$store.getters.getCurrentQuestionAnswer.value === value;
      },
      setValue: function( value,text ) {
        this.$store.dispatch('setQuestionAnswer', {
          value,
          questionId: this.question.id,
          answerText: text,
          questionType: this.question.type.slug
        } );
      }
    },
    computed: {
      question: function() {
        return this.$store.getters.getCurrentQuestion;
      },
      answerOrder: function () {
        const answer = this.$store.getters.getCurrentQuestion.answers;

        answer.sort(function(a, b) {
          if( a.order < b.order)
            return -1;
          if(a.order > b.order )
            return 1;
          return 0;
        })

        return answer;
      }
    },
  }
</script>
