
    import { Component, Vue } from 'vue-property-decorator';
    import SideDescriptionArea from "@/components/SideDescriptionArea.vue";
    import Wizard from "@/components/Wizard.vue";

    @Component({
        components: {
            Wizard,
            SideDescriptionArea,
        },
    })


    export default class Survey extends Vue {
        modules: Array<any> = [];


    }

