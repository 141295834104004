<template>
    <div class="question-handler">

        <div v-if=" questionType === 'OPEN_TEXT_SINGLE' || questionType === 'OPEN_NUMERIC' ">
            <InputField></InputField>
        </div>
        <div v-else-if=" questionType === 'LIST_SINGLE' ">
            <RadioField></RadioField>
        </div>

    </div>
</template>

<script>
  import InputField from "@/components/form/InputField";
  import RadioField from "@/components/form/RadioField";
  export default {
    name: "QuestionHandler",
    components: {RadioField, InputField},
    computed: {
      questionType: function() { return this.$store.getters.getCurrentQuestionType }
    }
  }
</script>

<style scoped>

</style>
